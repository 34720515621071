<template>
	<SignInContainer background-cover="/images/sign-in.png">
		<template #right-container>
			<div class="content">
				<TengivaLogo
					color="black"
					class="logo"
				/>
				<h1 class="title">{{ $t("pages.sign_in.title") }}</h1>
				<v-form
					v-model="state.form.valid"
					class="form-sign-in"
					@submit.prevent="async () => await onSubmit()"
				>
					<TextInput
						v-model="state.form.emailAddress"
						required
						:label="$t('general.email_address')"
						:rules="[checkEmpty, validEmail]"
						type="email"
					/>
					<TextInput
						v-model="state.form.passwrod"
						required
						type="password"
						:label="$t('general.password')"
						:rules="[checkEmpty]"
					/>

					<a
						class="footnote link__underline text-left block pl-[4px]"
						href="/forgot-password"
					>
						{{ $t("pages.sign_in.forgot_password") }}
					</a>

					<Checkbox
						v-model="state.form.rememberMe"
						class="mt-[24px]"
						:label="$t('general.remember_me')"
						hide-details
					/>

					<Button
						color="secondary"
						class="mt-[24px]"
						type="submit"
						:disabled="disableSignIn"
						:loading="state.form.loading"
						block
					>
						{{ $t("pages.sign_in.sign_in_button") }}
					</Button>

					<div
						v-if="state.form.errorMessage"
						class="mt-[32px]"
					>
						<Alert type="error">{{ state.form.errorMessage }}</Alert>
					</div>
				</v-form>
				<hr class="mt-[24px]" />
				<Button
					color="secondary"
					variant="outlined"
					class="mt-[24px]"
					@click="redirectToSignIn()"
				>
					Sign in on Tengiva Auth
					<Icon
						filled
						name="md-chevron_right"
					/>
				</Button>
			</div>
		</template>
	</SignInContainer>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import SignInContainer from "@/components/SignInContainer.vue";
import Button from "@/components/Button.vue";
import TextInput from "@/components/Input/TextInput.vue";
import Checkbox from "@/components/Checkbox.vue";
import TengivaLogo from "@/components/TengivaLogo.vue";
import Alert from "@/components/Alert.vue";
// import Icon from "@/components/Icon/index.vue";
const { t } = useI18n();
const route = useRoute();
useHead({
	title: t("pages.sign_in.meta.title"),
	meta: [{ name: "description", content: t("pages.sign_in.meta.description") }],
});
definePageMeta({
	layout: "unauthenticated",
});
const { checkEmpty, validEmail } = useFormValidations();
interface FormState {
	loading: boolean;
	emailAddress: string;
	passwrod: string;
	rememberMe: boolean;
	valid: boolean;
	errorMessage: string | null;
}
const state = reactive<{ form: FormState }>({
	form: {
		loading: false,
		emailAddress: "",
		passwrod: "",
		rememberMe: false,
		valid: false,
		errorMessage: null,
	},
});
const disableSignIn = computed(() => !state.form.valid);
onMounted(() => {
	const userEmailCookie = useCookie("user_email");
	if (userEmailCookie.value) {
		state.form.emailAddress = userEmailCookie.value;
		state.form.rememberMe = true;
	}
});
const { getSignInURL } = useAuth();
const { login } = useURM();
const userStore = useUserStore();
const orgStore = useOrgStore();
const { $sentry } = useNuxtApp();
const router = useRouter();
const onSubmit = async () => {
	if (state.form.loading) return;
	try {
		state.form.loading = true;
		const user = await login({
			email: state.form.emailAddress,
			password: state.form.passwrod,
			rememberMe: state.form.rememberMe,
		});
		userStore.setUser(user);
		orgStore.updateOrgs(user.organization_id);
		if (route.query.redirect) {
			router.push(route.query.redirect as string);
		} else {
			router.push("/");
		}
	} catch (err: any) {
		if (err.status === 401) {
			state.form.errorMessage = t("pages.sign_in.form.error.invalid_credentials");
		} else if (err.type === "invalid_origin") {
			state.form.errorMessage = t("pages.sign_in.form.error.invalid_account");
		} else {
			state.form.errorMessage = t("pages.sign_in.form.error.submitted_error");
			$sentry.captureException(`Can not sign in with password, ${err.message}`);
		}
	} finally {
		state.form.loading = false;
	}
};

function redirectToSignIn() {
	window.location.href = getSignInURL(route.query.redirect as string | undefined);
}
</script>

<style lang="scss" scoped>
.content {
	@apply h-full min-h-[864px] mobile:pt-[40px] pt-[155px];
	.logo {
		@apply mx-auto h-[56px] w-[292px];
	}
	.title {
		@apply font-dm-serif text-[32px] font-normal leading-[40px] text-black mt-[24px];
	}
	.subtitle {
		@apply text-[14px] leading-[24px] text-neutral-700;
	}
	.form-sign-in {
		@apply mx-auto w-full max-w-[400px] mt-[24px];
	}
	.footnote {
		@apply text-[14px] leading-[20px];
	}
	.link__underline {
		@apply text-black underline cursor-pointer;
	}
}
</style>
